@font-face{
  font-family: "OCRA";
  src: url(../../src/assets/fonts/OCRAEXT.TTF) format('truetype');
}

@font-face{
  font-family: "SFPro-m";
  src: url(../../src/assets/fonts/SFProDisplay-Medium.ttf) format('truetype');
}

@font-face{
  font-family: "SFPro";
  src: url(../../src/assets/fonts/FontsFree-Net-SFProDisplay-Black.ttf) format('truetype');
}

@font-face{
  font-family: "SFPro-r";
  src: url(../../src/assets/fonts/SFProDisplay-Regular.ttf) format('truetype');

}


.wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: "SFPro-r",Poppins;
  gap:20px;
}
@media screen and (max-width: 600px) {
  .wrapper {
    flex-direction: column;
  }
}
.form-wrapper {
  width:100%;
  margin-top:40px;
  display: flex;
  flex: 2;
  justify-content:space-evenly;
  flex-direction: column;
  text-align: left;
  color: black;
}

.details-wrapper {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
  color: white;
}

form {
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
}

h1 {
  /* text-align: center; */
  width: 100%;
  color: #111;
  font-weight: lighter;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
}

.form-wrapper h2{
  text-align: left;
  color: #111;
  font-weight: 900;
  font-family: "SFPro", SFPro-r;
  padding-bottom: 20px;
  font-size: 48px;
}

.form-wrapper label {
  font-size: 14px;
  margin-bottom: 0.25em;
  color: #c8cacd;
  font-weight:900;
}
/* 
.form-wrapper form{
  padding-right: 30px;
} */

input,
textarea,input:active, textarea:active {
  padding: 12px;
  outline: none;
  border: none;
  border-bottom: 1px solid gray;
}


input::placeholder {
  font-size: 20px;
  font-weight: lighter;
  color: gray;
}

textarea::placeholder {
  font-size: 20px;
  font-weight: lighter;
  color: gray;
  border-bottom: 1px solid gray;
}

input::placeholder:active, textarea::placeholder:active{
  border-bottom: 1px solid black;
}

input.error {
  border: 1px solid red;
}
textarea.error {
  border: 1px solid red;
}

.errorMessage {
  color: red;
  font-size: 0.75em;
  display: relative;
}

.firstName {
  margin-right: 1%;
}

.lastName {
  margin-left: 1%;
}

.firstName,
.lastName,
.email,
.message-1,
.contact {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 12px;
  font-family: "SFPro-m", Poppins;
  font-size: 13px;
}
/* 
.firstName :focus-within ,
.lastName :focus-within,
.email :focus-within,
.message-1 :focus-within,
.contact :focus-within {
   background-color: lightcoral;
} */


input:focus +label{
  color:black;
}


.firstName,
.lastName {
  width: 100%;
}

.email,
.message-1,
.contact {
  width: 100%;
}

/* .createAccount {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 1rem;
} */

/* .createAccount button{
    border-radius: 8px;
    coration: none;text-de
    display:inline-block;
    background-color:#0ea5e9;
    padding:12px 20px;
    color:white;
    font-size: 1rem;
    width:170px;
    border:none;
    font-weight: 800;
} */

.createAccount small {
  color: lightcoral;
}
 .contactpage-image{
  /* width:40%; */
  flex:1;

} 
 .contactimage{
  object-fit: cover;
  width: 120%;
  max-width:initial;
}

.footer-divider{
  margin:0;
}

/* ****************** */
/* 
.defaultButton-f , .defaultButton-f:focus{
  border-radius: 8px;
  text-decoration: none;
  display:inline-block;
  background-color:#0ea5e9;
  padding:12px 20px;
  color:white;
  width:170px;
  border:none;
}

.arrowdash-f{
  position:relative;
  width: 50px;
  margin-left: 0px;
  display: inline-block;
  vertical-align:center;
}

.dash-f{
 width:38px;
 height:1px;
 margin:-8px;
 top:0px;
 display:inline-block;
 background-color:white;
 position:absolute;
 transition: width 0.3s ease-in-out;
 -webkit-transition: width 0.3s ease-in-out ;
}

.triangle-right-f {
  top:0px;
  transform: translateX(44px) translateY(-45%);
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid white;
  border-right: 6px solid transparent;
  position: absolute;
  margin:-7.1px;
  margin-left: -15px;
  opacity: 1;
}


.defaultButton-f:hover{
  color:white;
  width: 200px;
  text-decoration: none;
  transition: 0.3s ease-in-out;
}
.defaultButton-f:hover .dash-f{
  width:125%;
  transition: 0.3s ease-out;
}
.defaultButton-f:hover .triangle-right-f{
  opacity: 0;
  transition: 0.3s ease-out;
} */
/* *********************** */
.defaultButton , .defaultButton:focus{
  border-radius: 8px;
  text-decoration: none;
  display:inline-block;
  background-color:#0ea5e9;
  padding:12px 20px;
  color:white;
  position:relative;
  border:none;
  outline: none;
}
.submit-textform{
  position:absolute;
  left:20px;
}

.defaultButton:hover{
  color:white;
  width: 200px;
  text-decoration: none;
  /* transition: 0.8s ease-in-out; */
}
.defaultButton.submit-button:hover{
  width:170px;
  
}


.defaultButton:hover .dash{
  width:120%;
  transition: 0.3s ease-out;
}
.defaultButton:hover .triangle-right{
  opacity: 0;
  /* transition: 0.3s ease-out; */
}

.arrowdash{
  position:relative;
  width: 50px;
  background-color: blueviolet;
  display: inline-block;
  vertical-align:center;
}
.arrowdash.arrowdash-margin{
   margin-left: 45px;
   right:10px;
}

.dash {
 width:38px;
 height:1.4px;
 margin:-8px;
 margin-left:3px;
 top:0px;
 display:inline-block;
 background-color:white;
 position:absolute;
 transition: width 0.3s ease-in-out;
 -webkit-transition: width 0.3s ease-in-out ;
}

.triangle-right{
  top:0px;
  transform: translateX(44px) translateY(-45%);
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid white;
  border-right: 6px solid transparent;
  position: absolute;
  margin:-7.1px;
  opacity: 1;
}

/* ********************* */
.success {
  color: lightgreen;
}

.footer-content{
  /* background-color: #2a2c30; */
  padding: 40px 0px 40px 0px;
  display: flex;
  justify-content: space-between;
}
.contactContainer {
  padding-top: 80px;
  overflow-x: hidden;
}
.contactTitle {
  padding: 24vh;
  font-size: 500%;
  font-weight: bold;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
}
/* 
.contact-image{
  width:200px;
} */

@media screen and (max-width: 1000px) {
  .contactContainer {
    padding-top: 70px;
  }
}

@media screen and (max-width: 900px) {
  .contactTitle {
    padding: 22vh;
  }
}


@media screen and (max-width: 500px) {
  .contactTitle {
    padding: 12vh;
    font-size: 250%;
  }
}

@media only screen and (max-width:800px){
  .contactpage-image{
    display:none;
  }
  .contactTitle {
    padding: 25px;
    font-size: 250%;
  }
  .form-wrapper{
    width:90%;
    margin-left:12px;
  }

  .form-wrapper h2{
    font-size:32px;
  }
  .button{
    width:50px;
  }
}

@media only screen and (min-width:601px) and (max-width: 900px) {
  .contactpage-image{
    display:none;
  }

  .contactTitle {
    padding: 12vh;
    font-size: 300%;
  }
  /* .contactimage{
    object-fit: cover;
    width: 150%;
    max-width:initial;
  } */
  
}

@media only screen and (min-width:901px) and (max-width: 1400px) {
  .contactimage{
    object-fit: cover;
    width: 200%;
    max-width:initial;
  }
  
  .contactTitle {
    padding: 12vh;
    font-size: 300%;
  }

}


@media only screen and (min-width:601px) and (max-width: 900px) {
  .contactpage-image{
    display:none;
  }

}

