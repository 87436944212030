@import url("https://fonts.googleapis.com/css?family=Poppins");
.aboutu-grid {
  background: #141e30; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #243b55,
    #141e30
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #243b55,
    #141e30
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.service_1 {
  content:url("../assets/images/service_1.png");
}
.service_2 {
  content:url("../assets/images/service_2.png");
}
.service_3 {
  content:url("../assets/images/service_3.png");
}
.service_4 {
  content:url("../assets/images/service_4.png");
}

.aboutusbackground {
  /* content:url("../assets/images/aboutus.png"); */
  background-image:url("../assets/images/aboutus.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-filter: brightness(30%);
  position: absolute;
  z-index: -1;
  width: 100vw;
  height: 60%;
  overflow-x: hidden;
}

.servicesbackground {
  /* content:url("../assets/images/ourservices.jpg"); */
  background-image: url("../assets/images/ourservices.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-filter: brightness(40%);
  position: absolute;
  z-index: -1;
  width: 100vw;
  height: 60%;
  overflow-x: hidden;
}

.aboutusTitle {
    padding: 24vh;
    font-size: 500%;
    font-weight: bold;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.servicesTitle {
  padding: 24vh;
    font-size: 500%;
    font-weight: bold;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.banner-text {
  width: 75%;
  margin: auto;
  border-radius: 10px;
}

.banner-text h1 {
  font-size: 4vw;
  font-weight: bold;
  text-align: center;
  padding: 15px 15px 20px;
  font-family: Poppins;
}

/* .banner-text h5 {
  text-align: left;
  padding: 25px 15px 20px;
  font-family: Poppins;
  font-size: 19px;
  letter-spacing: 1px;
  line-height: normal;
} */

.banner-text p {
  text-align: left;
  padding: 25px 15px 20px;
  font-family: Poppins;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: normal;
}

.banner-text hr {
  border-top: 5px dotted #f4f4f7;
  width: 5%;
  margin: auto;
}

.person-wrapper {
  padding: 15px 15px 20px;
  position: relative;
  z-index: 1;
  width: 100%;
}

.person-wrapper {
  background-color: #243b55;
}

.person-wrapper p {
  margin: 0;
  font-size: 20px;
  text-align: left;
  font-family: Poppins;
  color: white;
}

.person-wrapper h3 {
  padding: 10px 0px;
  font-size: 35px;
  text-align: center;
  font-family: Poppins;
  color: white;
}

.inner-content {
  position: relative;
  z-index: 1;
  padding: 160px 30px 70px;
  text-align: center;
  color: #fff;
}
.inner-content h1 {
  position: relative;
  z-index: 1;
  padding: 160px 30px 70px;
  text-align: center;
  font-family: Poppins;
  font-size: 100pt;
  color: white;
}
.slide-img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  background-size: cover;
  -webkit-background-position: 50% 0;
  background-position: 50% 0;
}
.slide-img img {
  width: 100%;
}
.banner-section {
  background-image: url(../assets/images/ourservices.jpg);
}
.particleBackground{
  background: #00004d;
   width: 100%;
  position: absolute;
  z-index: -1;
  height: 100vh;
  object-fit: fill;
  background: #000428;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #004e92, #000428);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #004e92, #000428); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}


.inner-banner.bg-dark:after {
  display: block;
  z-index: 0;
}

.scroll-down {
  position: absolute;
  text-align: center;
  width: 22px;
  height: 22px;
  border-style: solid;
  border-color: #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 30px;
  z-index: 2;
  -webkit-animation: up-down 0.5s ease-in-out infinite alternate;
  animation: up-down 0.5s ease-in-out infinite alternate;
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
}

.service-text h5 {
  text-align: center;
  padding: 100px 100px 100px 100px;
  font-size: 1.5vw;
  font-family: Poppins;
  color: #fff;
  z-index: 1;
}
.services-header h1 {
  text-align: justify;
  object-fit: stretch;
  padding: 100px 100px 100px 100px;
  font-size: 4vw;
  font-family: Poppins;
  text-justify: auto;
  position: relative;
  color: #fff;
  z-index: 1;
}

@media screen and (max-width: 800px) {
  .services-header h1 {
    padding: 10px 10px 10px 10px;
  }
  .service-text h5 {
    padding: 15px 15px 15px 15px;
  }
  .person h3,
  .person1 h3{
padding: 15px 15px 15px 15px;
  }
  .person p,
  .person1 p{
    padding: 15px 15px 15px 15px;
  }
}


.person {
  color: white;
  font-size: 19px;
  padding: 15px 15px 20px;
  position: relative;
  z-index: 1;
  width: 100%;
  
}
.person1{
  color: black;
  font-size: 19px;
  padding: 15px 15px 20px;
  position: relative;
  z-index: 1;
  width: 100%;
  
}

.person h3,
.person1 h3 {
  padding: 70px 10px 10px 0px;
  font-size: 40px;
  font-family: Poppins;
}

.person p,
.person1 p {
  margin: 0;
  padding: 30px 10px 10px 10px;
  margin-bottom: 100px;
  font-size: 20px;
  letter-spacing: 1px;
  text-align: left;
  font-family: Poppins;
}
.person img,
.person1 img {
  height: 100%;
  width: 100%;
  color: #243b55;
  padding-right: 10px;
}

.person:after {
  content: "";
  background: #243b55;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  position: absolute;
  z-index: -1;
}

.person:after,
.person,
.person p,
.person1 p {
  -moz-transition: all 550ms cubic-bezier(0.45, 1, 0.32, 1);
  -webkit-transition: all 550ms cubic-bezier(0.45, 1, 0.32, 1);
  -o-transition: all 550ms cubic-bezier(0.45, 1, 0.32, 1);
  -ms-transition: all 550ms cubic-bezier(0.45, 1, 0.32, 1);
  transition: all 550ms cubic-bezier(0.45, 1, 0.32, 1);
}

.why-choose-contentbox {
  padding: 100px;
  color: #fff;
}
.why-choose-contentbox h2 {
  color: #fff;
  margin-bottom: 55px;
}
.why-choose-col {
  position: relative;
  font-size: 0;
}
.why-choose-col > i {
  font-size: 50px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: rgba(36, 36, 36, 0.28);
  text-align: center;
  line-height: 84px;
  display: inline-block;
  vertical-align: middle;
}
.why-choose-content {
  overflow: hidden;
  font-size: 15px;
  display: inline-block;
  vertical-align: middle;
  padding-left: 30px;
  width: -moz-calc(100% - 90px);
  width: -webkit-calc(100% - 90px);
  width: calc(100% - 90px);
}
.why-choose-content h3 {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 5px;
}
.why-choose-col + .why-choose-col {
  margin-top: 25px;
}
.why-choose-content p {
  margin: 0;
}

.background {
  position: relative;
  
  height: 100vh;
  width: 100%;
}
.servicesContainer {
  padding-top: 80px;
  overflow-x: hidden;
}
.aboutContainer {
  padding-top: 80px;
  overflow-x: hidden;
}

@media screen and (max-width: 1000px) {
  .aboutContainer {
    padding-top: 70px;
  }
  .servicesContainer {
    padding-top: 70px;
  }
}

@media screen and (max-width: 900px) {
  .aboutusTitle {
    padding: 22vh;
  }
  .servicesTitle {
    padding: 22vh;
  }
}

@media screen and (max-width: 800px) {
  .services-header h1 {
    font-size: 6vw;
    text-align: center;
    font-weight: 900;
    padding: 20px 10px 0px 10px;
  }
  .service-text h5 {
    font-size: 4vw;
    padding: 15px 55px 15px 55px;
  }
  .person h3,
  .person1 h3 {
    font-size: 8vw;
    text-align: left;
padding: 10px 10px 10px 10px;
  }
  .person p,
  .person1 p{
    text-align: left;
    padding: 10px 10px 10px 10px;
  }
  /* .banner-text h5{
    text-align: left;
    padding: 30px 0px 10px 0px;
  } */
  .banner-text p{
    text-align: left;
    padding: 30px 0px 10px 0px;
  }
  .banner-text h1{
    text-align: center;
    font-size: 8vw;
    padding: 10px 0px 10px 0px;
  }
  .person-wrapper p{
    text-align: left;
    padding: 10px 10px 10px 10px;
  }
  .background{
    height: 100vw;
  }
  .particleBackground{
  height: 100vh;
  }
  .aboutusTitle {
    padding: 13vh;
    font-size: 400%;
  }
  .servicesTitle {
    padding: 13vh;
      font-size: 400%;
  }
  .servicesbackground {
    height: 35%;
  }
  .aboutusbackground {
    height: 35%;
  }
}

@media screen and (max-width: 500px) {
  .aboutusTitle {
    padding: 12vh;
    font-size: 300%;
  }
  .servicesTitle {
    padding: 12vh;
    font-size: 300%;
  }
}

@media screen and (max-width: 600px) {
  .aboutusTitle {
    padding: 11vh;
    font-size: 280%;
  }
  .servicesTitle {
    padding: 11vh;
    font-size: 280%;
  }
  .aboutusbackground {
    /* content:url("../assets/images/aboutus_mobile.jpg"); */
    /* background-size: cover; */
    background-image:url("../assets/images/aboutus.png");
  }
  .servicesbackground {
    /* content:url("../assets/images/ourservices_mobile.jpg"); */
    background-image:url("../assets/images/ourservices_mobile.jpg");
  }
  /* .service_1 {
    content:url("../assets/images/service_1_mobile.png");
  }
  .service_2 {
    content:url("../assets/images/service_2_mobile.png");
  }
  .service_3 {
    content:url("../assets/images/service_3_mobile.png");
  }
  .service_4 {
    content:url("../assets/images/service_4_mobile.png");
  } */
}