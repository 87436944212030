body {
  margin: 0; 
  padding: 0px 100px 0px 100px;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

input , label 
{
  margin: 0;
}

@media only screen and (max-width: 600px) {
  body{
    padding:0px 20px 0px 20px;
  }
}

@media only screen and (min-width:601px) and (max-width: 900px) {
  body{
    padding:0px 50px 0px 50px;
  }
}

@media only screen and (min-width:901px) and (max-width:1400px) {
  body{
    padding:0px 100px 0px 100px;
  }
}

@media only screen and (min-width:1401px)  {
  body{
    padding:0px 100px 0px 100px;
  }
}