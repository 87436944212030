
@font-face{
  font-family: "SFPro-r";
  src: url(../assets/fonts/SFProDisplay-Regular.ttf) format('truetype');
}

@function toRem($value) {
  $remValue: ($value / 16) + rem;
  @return $remValue;
}

input:focus,
textarea {
  outline: none !important;
  border-bottom: 1px solid white;
}

.main-container {
  width: 100%;
  height: 100vh;
  background-color: red;
  box-sizing: border-box;
  padding: 1rem;
}

.main-container1 {
  width: 100%;
  height: auto;
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

// Icon Design
.icon-outer {
  width: 3.375rem;
  height: 3.375rem;
  border: 1px solid #9875ff;
  box-sizing: border-box;
  filter: drop-shadow(0px 2px toRem(10) #9875ff);
  border-radius: 100%;
}

.icon-inner {
  width: 3.1rem;
  height: 3.1rem;
  position: absolute;
  left: 2.69%;
  right: 47.69%;
  top: 2.23%;
  bottom: 87.39%;
  background: #1f1e1e;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 100%;
}

#img1 {
  width: 0.8rem;
  position: absolute;
  left: 29.32%;
  right: 47.54%;
  top: 31.54%;
  bottom: 89.37%;
}

#img2 {
  width: 0.8rem;
  position: absolute;
  left: 41.75%;
  right: 49.11%;
  top: 32.64%;
  bottom: 87.27%;
}

//Message
.message {
  height: 2.25rem;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 2.25rem;
  padding-top: 1.5rem;
  margin:1rem;
  display: flex;
  align-items: left;
  justify-content: center;
  color: #ffffff;
}

//new code //ilakiyan
//entry container
.entryContainer {
  width: 100%;
  height: 100vh;

  background-color: #111827;
  display: flex;
  justify-content: center;
  align-items: center;


  &__logo {
    width: 200px;
    height: 200px;
  }
//0EA5E9
  &__label {
    font-size: 0.8rem;
    color: #343A47;
    text-transform: uppercase;
    margin: 0 !important;
    display: block;
  }

  &__textInput {
    background-color: transparent;
    border-color: #343A47 ;
    margin: 0 !important;
    margin-top: 10px !important;
    color: white;
    width: 100%;
    text-align: center;
    font-weight: bold;

    &:focus , &:active {
      border-color: #0EA5E9;      
    }
  }

  &__description {
    font-size: 1.3rem;
    color: white;
    margin: 0;
  }

  &__userInputsContainer {
    margin-top: 20px;
  }

  &__userInputs {
    margin-top: 40px;

    &:focus-within {
      label {
        color: #0EA5E9;
      }
    }
  }
  .leftContainer {
    width : 400px;
    height: 400px;
    background-color: #111827;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rightContainer {
    padding: 10px 40px;
    width: 400px;
    height: 400px;
    background-color: #111827;
    text-align: center;


    .joinButton {
      margin-top: 40px;
      padding: 10px 12px;
      background-color: #0EA5E9;
      border-radius: 4px;
      outline: none;
      border: none;
      width: 100%;
      color: white;
      font-weight: bold;
      font-size: 0.8rem;
    }
  }
}

@media only screen and (max-width:600px){
  .entryContainer {
    flex-direction: column;
    justify-content: flex-start;

    .leftContainer {
      margin-top: 20%;
    }
    .leftContainer,.rightContainer {
      height: initial;
    }
    &__logo {
      width: 120px;
      height: 120px;
    }

    &__description {
      margin-top: 40px;
      font-size: 1rem;
      text-align: center;
      margin: 40px 30px 0;
    }

    &__userInputsContainer {
      margin-top: 40px;
    }

    .rightContainer {
      .joinButton {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      width: 90%;
      }
    }
  }
  }  //controls-container
.controls-container {
  width: 100%;
  height: 70%;
  border: 0.5px solid #6475a0;
  box-sizing: border-box;
  border-radius: toRem(10);
  display: flex;
}

.cc1 {
  width: 60%;
  height: 100%;
  box-sizing: border-box;
  padding-left: 10%;
  .controls1 {
    width: 100%;
    height: 10%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: toRem(18);
    line-height: toRem(27);
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  .controls2 {
    width: 100%;
    height: 80%;
    background: #202531;
    border-radius: toRem(20);
    display: flex;
    flex-direction: column;
    position: relative;
    .controls21 {
      width: 100%;
      height: 82%;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 600;
      font-size: toRem(15);
      line-height: toRem(22);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
    }
    .controls22 {
      width: 100%;
      height: 0.5%;
      border: 0.5px solid #31394b;
      box-sizing: border-box;
      border-radius: toRem(10);
      transform: matrix(1, 0, 0, -1, 0, 0);
    }
    .controls23 {
      width: 100%;
      height: 17.5%;
      .toggle-video-outer {
        position: absolute;
        width: toRem(40);
        height: toRem(18);
        top: 89.8%;
        left: 10%;
        background: rgba(177, 177, 177, 0.4);
        box-shadow: inset 0px 4px 4px rgba(167, 167, 167, 0.25);
        border-radius: toRem(14);
      }
      .toggle-video-inner {
        position: absolute;
        width: toRem(18);
        height: toRem(18);
        background: #9b9b9b;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: toRem(11.5);
      }
      #img3 {
        position: absolute;
        width: 1rem;
        top: 90%;
        left: 4%;
      }

      #img4 {
        position: absolute;
        width: 0.6rem;
        top: 90%;
        left: 92.4%;
      }
      #img5 {
        position: absolute;
        width: 1.2rem;
        top: 88.9%;
        left: 91.7%;
      }
    }
  }
}

.cc2 {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  .controls3 {
    width: 80%;
    height: 40%;
    margin-top: 14%;
    border: 0.5px solid #6475a0;
    box-sizing: border-box;
    border-radius: toRem(10);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 0.3rem 0;

    input {
      display: block;
      height: 25%;
      width: 80%;
      background: #192734;
      border-style: none;
      text-align: center;
      color: #ffffff;
      font-size: 1rem;
    }
    .wronginput {
      display: block;
      height: 25%;
      width: 80%;
      background: #192734;
      border-style: none;
      text-align: center;
      // color: #ffffff;
      font-size: 1rem;
    }
  }
  .controls4 {
    width: 100%;
    height: auto;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: toRem(12);
    line-height: toRem(18);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
  }
}

.cc3 {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  padding-top: 1.5%;
  .cancel {
    width: 15%;
    height: toRem(45);
    border: 1px solid #b4b4b4;
    box-sizing: border-box;
    border-radius: toRem(10);
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: toRem(13);
    line-height: toRem(20);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    cursor: pointer;
  }

  .join {
    width: 15%;
    height: toRem(45);
    margin-left: 3%;
    border: 1px solid #0061fe;
    box-sizing: border-box;
    border-radius: toRem(10);
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: toRem(13);
    line-height: toRem(20);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    cursor: pointer;
    background: #0061fe;
  }
}

.blue {
  color: rgb(17, 117, 247);
}

.joining {
  opacity: 0.4;
}

.loaderRow {
  display:flex; 
  flex-direction:row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.loaderText {
  margin-left: 20px;
  margin-top: 15px;
}

.confirmScreenWrapper {
  background-color: #111827;
  width: 100vw;
  height: 100vh;
}

//preview screen styles
.confirmJoinContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 480px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #111827;


  @media screen and (max-width: 600px) {
    position: initial;
    padding-top: 20%;
    transform: translate(0,0);
    width: 100vw;
    align-items: flex-start;
    height: 100vh;
    overflow-y: hidden;
  }

  .previewVideoContainer {
    height: 480px;
    background-color: #111827;

    @media screen and (max-width: 600px) {
      width: 90vw;
      height: 70vw;
      margin: 0 auto;
  }

  }

 

  .joinButton {
    background-color: #0DA5E9;
    border-radius: 4px;
    color: white;
    outline: none;
    border: none;
    // height: 66px;
    // width: 106px;
    padding: 6px;
    margin-top: 40px;
    font-size: 1.2rem;
    font-weight: 500;

    @media screen and (max-width: 600px) {
      position: absolute;
      bottom: 20px;
      width: 90%;
      left: 5%;
    }
  }

  .previewVideoActionContainer {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 0px;

    .joinButton {
      padding: 12px 24px;
    }

    @media screen and (max-width: 600px) {
      width: 90vw;
      margin: 0 auto;
      justify-content: center;
    }

  }
  .previewVideoActionButtons {
    display: flex;
    margin-top: 40px ;
    align-items: center;
  }

  .previewVideoActionButton {
    background-color: #343A47;
    border-radius: 50%;
    width: 66px;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;

    .mutedIcon {
      width: 25px;
      height: 25px;
    }
  }

  .previewVideoActionButton#video {
    margin-left: 10px;
  }

}