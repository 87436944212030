@font-face{
  font-family: "OCRA";
  src: url(../section2/OCRAEXT.TTF) format('truetype');
}

@font-face{
  font-family: "SFPro";
  src: url(../section2/FontsFree-Net-SFProDisplay-Black.ttf) format('truetype');
}

@font-face{
  font-family: "SFPro-r";
  src: url(../section2/SFProDisplay-Regular.ttf) format('truetype');
}



h2{
  color:black;
  font-family:"SFPro";
  font-size:36px;
}

h4{
  color:#0EA5E9;
  font-size:16px;
  text-align: left;
}

p.thicker {
  font-weight: 1000;
}

.left2-box {
    /* background-color:greenyellow; */
    align-items: center;
    margin-right: 10px;
    margin-top: 100px;
  }
.intro-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro-an{
  width:100%;
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
}
  .my-box{
    width:25%;
    /* background-color:cyan; */
    margin-left: 10px;
    position:relative;
    text-align: left;
    box-sizing: border-box;
    margin-bottom: 5px;
    border: none;

  }

  /* .my-box.firstElement {
    margin-left: 0;
  } */

  .intro-content {
    display: flex;
    flex-direction: column;
    /* align-items: left; */
    height: 210px;
    border-radius: 8px;
    border: 2px solid #1D243233 ;
    padding:25px;
  }

  .intro-content p {
    font-size: 18px;
    color: black;
    font-family: "SFPro";
    line-height: normal;
    margin-top: 30px;
  }

  .processFlowContainer {
    /* width: 100vw; */
    /* height: 500px; */
    margin-top: 40px;

  }

  .stepsContainer {
    display: flex;
    border: 7px;
    border-color: #0EA5E9;
    margin-top: 60px;

    /* background-color:blue; */
}

  .circle{
    height: 45px;
    width: 45px;
    background-color: #0EA5E9;
    border-radius:50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .circle span {
    color: white;
    font-size: 18px;
  }

  @media only screen and (max-width:600px){
    h2{
      margin-bottom:10px;
      font-size:24px;
      font-family: "SFPro";
    }
    .stepsContainer{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left:0px;
    }
    .stepsContainer:first-child {
      margin-left: 0px;
    }
    .my-box{
      width:85vw;
      left:0px;
      margin-bottom: 15px;
      font-size: 800;
      margin-left: 0;
    }
    .circle{
      margin-bottom: 20px;
    }
    .upper-part , .left2-box {
      margin-top: 40px;
    }
  }

  @media only screen and(min-width:601) and (max-width:800px){
    h2{
      margin-bottom:10px;
      font-size:24px;
      font-family: "SFPro";
    }
    .stepsContainer{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-left:0px;
    }
    .stepsContainer:first-child {
      margin-left: 0px;
    }
    .my-box{
      width:85vw;
      left:0px;
      margin-bottom: 15px;
      font-size: 800;
      background-color: aqua;
    }
    .intro-content{
      height:300px !important;
    }
    .circle{
      margin-bottom: 20px;
    }
    .upper-part , .left2-box {
      margin-top: 40px;
    }
  }

  /* Media Query for Large screens */
  @media (min-width: 1400px) {
    
}