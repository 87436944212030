.red {
  background: #ff5555;
}
.greyBg {
  background-color: #4d597a;
}
.bluebg {
  background: #0061fe;
}
::-webkit-scrollbar {
  display: none;
}
.main-container2 {
  width: 100vw;
  height: calc(100vh - 50px); // fixed top bar
  // background-color: #192734;
  background-color: #111827;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;

  @media only screen and (max-width:600px){
    margin-top: 80px;
    height: calc(100vh - 210px) ;
    overflow-y: scroll;
  }
}

.recording-message {
  width: 100%;
  position: absolute;
  top: 0;
  height: 50px;
  background: #4d597a;
  color: #ffffff;
  z-index: 1500;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
}

.child1 {
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  height: 50px;
  padding: 0 40px;
  top: 0;
  background-color: #111827;

  @media only screen and (max-width:600px){
    height: 80px;
  }
  .title {
    // width: 50%;
    height: 100%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    color: #ffffff;
    @media only screen and (max-width:600px){
      display: none;
    }

  }
  .toggle-list-btn {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    cursor: pointer;
    background: #4d597a;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .dot {
      margin: 0.1rem;
      // width: 120%;
      // height: 250%;
      width: 40%;
    }

    @media only screen and (max-width:600px){
      display : none; 
    } 
  }

  .leaveFromTop {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 5px;

    .call {
      width: 50%;
    }

    @media only screen and (min-width:600px){
      display: none;
    }
  }
  .recording-indicator {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .record {
      width: 1rem;
      height: 1rem;
    }
  }
}

.videoPlaceholder {
  background-color: #111827;
  font-size: 1.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  width: 480px;
  background-color: #253351 !important;
  z-index: 10;
}

#video-container {
  width: 100%;
  height: 80%;
  margin: 0;
  display: flex;

  @media only screen and (max-width:600px){
    height: auto;
    width: auto;
  }
  #contentshare-container {
    width: 100%;
    height: 100%;

    > div {
      background-color: #111827;
    }
   

    @media only screen and (max-width:600px){
      width: 98vw;
      position: absolute;
      z-index: 1000;
      left: 1%;
      height: calc(100vh - 210px);
      top: 80px;
    }
  }



  #remote-video-container {
    width: 100%;
    // justify-content: center;
    // align-items: center;
    // flex-wrap: wrap;
    overflow-y: auto;
    padding: 10px;

 

    

    display: grid;
    grid-gap: 0.4em;
    grid-template-columns: repeat(auto-fit,minmax(20em,1fr));
    ::-webkit-scrollbar {
      display: none;
    }





    @media only screen and (max-width:600px){
      grid-template-columns: repeat(2,1fr);
      grid-gap: 0.2em;


    }
    
    .attendee-video-container {
      // width: 9.375rem;
      // height: 9.375rem;
      // min-height: 180px;
      // max-height: 200px;
      background: #000000;
      position: relative;
      overflow: hidden;

      > div:not(.tileControls) {
        width: 100% !important;
        height: 100% !important;
        margin: 0 auto;

        .ch-video {
          object-fit: cover !important;
          width: 100% !important;
          height: 100% !important;
          background-color: transparent !important;
        }
      }
      @media only screen and (min-width:1000px){
        > div:not(.tileControls) {
          max-width: 80vw;
        }
      }
      @media only screen and (max-width:600px){
        width: 45vw;
        height: 45vw;
      }
      &.localUser {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 280px;
        height: 280px;
        z-index: 10;
        padding: 4px;

        &.isSharing {
          width: 200px;
          height: 200px;
        }

        @media only screen and (max-width:600px){
         position: relative;
          height: 45vw;
          width: 45vw;
        }
      }


      .tileControls {
        display: flex;
        position: absolute;
        bottom: 15%;
        left: 50%;
        transform: translateX(-50%);
        // height: 12%;
        // min-width:  400px;
        width: fit-content;
        justify-content: space-between;
        align-items: center ;
      }

      .nameContainer {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 0.8rem;
        text-align: center;
      }

      .nameText {
        margin-right: 10px;
        color: #ffffff;
        padding: 10px 14px;
        background-color: #777882;
        opacity: 0.3;
        border-radius: 30px;
        width: fit-content;
        text-transform: capitalize;

        @media only screen and (max-width:600px){
          padding: 6px 10px;
        }
      }
      .mute-status {
        background: #dcdcdc;
   
        width: 2.2em;
        height: 2.2em;
        // width: 18%;
        // height: 16%;
        border-radius: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width:600px){
          width: 1.8em;
          height: 1.8em;
        }

        .mut {
          width: 60%;
          height: 60%;
        }
        .unmut {
          width: 60%;
          height: 60%;
        }
      }

      
    }
  }
}

.actions-container {
  height: 10%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  position: relative;

  > div {
 margin-left: 20px;
  }

  @media only screen and (max-width:600px){
    position: fixed;
    bottom : 0;
    height: 130px;
    width: 100%;
    justify-content: center;
    background-color: #111827;

    >div {
      margin: 0 5px;
      width: 60px !important;
      height: 60px !important;
    }
  }
  #video {
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    .videoff,
    .videoon {
      width: 40%;
      height: 40%;
    }
  }

  #leave {
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    .call {
      width: 40%;
      height: 40%;
    }

    @media only screen and (max-width:600px){
      display : none;
    }
    
  }

  .leaveFromTop {
    width: 44px !important;
    height: 44px !important;
  }

  #voice {
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .mute1 {
      width: 40%;
      height: 40%;
    }
    .mute2 {
      width: 40%;
      height: 40%;
      position: absolute;
      left: 28.33%;
      right: 8.33%;
      top: 28.55%;
      bottom: 9.55%;
    }
  }
  #screen-share {
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    .screen {
      width: 40%;
      height: 40%;
    }
  }
}

#roster-container {
  position: absolute;
  z-index: 100;
  right: 0;
  width: 380px;
  max-width: 100%;
  height: calc(100% - 50px);
  background: #111827;

  @media screen and (max-width:600px){
    height: calc(100% - 200px);
  }
  aside {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #111827;
    border: none !important;
      box-shadow: none !important;
      overflow-y: scroll;



    div {
      margin: 0.1rem 0;
      background: #192734;
      color: white;
    }
  }
}


// .container{
// top: 0px;
// left: 0px;
// width: 1920px;
// height: 90px;
// background: blue 0% 0% no-repeat padding-box;
// opacity: 1;
// }



.container{
        top: 0px;
        left: 0px;
        width: 80%;
        opacity: 1;

  }

.iconcontainer{
  width:200px;
  height:50px;
  align-items: center;
  background-color: #ff5555;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #0061fe;
  font-weight: 600;
  font-size: 25px;
}
.iconImage{
  margin-right: 5px;
}

@media screen and (min-width:600px){
  .container{
    min-width:100%;
    align-items: center;
  }
}

.toggleChatFromBottom {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media only screen and (min-width:600px){
    display: none;
  }

}

#video-container #remote-video-container .attendee-video-container {
 
  &.isSharing {
    width: 194px;
    height: 170px;
  }

}

#video-container #remote-video-container {
   &.isSharing {
    height: 100%;
     width : 200px;
     grid-template-columns: repeat(1, 1fr);
     grid-column-gap: 10px;
   }
     &.count-1 , &.count-2 , &.count-3 , &.count-4 {
       max-height: 510px;
     }

     &.count-5 , &.count-6 , &.count-7 , &.count-8 {
       max-height: 1000px;
     }

     &.count-9 , &.count-10 , &.count-11 &.count-12 {
       max-height: 1000px;
     }

     
}