@import url("https://fonts.googleapis.com/css?family=Poppins");

@import url("https://fonts.googleapis.com/css?family=Courgette");

@font-face{
    font-family: "OCRA";
    src: url(../assets/fonts/OCRAEXT.TTF) format('truetype');
  }
  
@font-face{
    font-family: "SFPro-Black";
    src: url(../assets/fonts/FontsFree-Net-SFProDisplay-Black.ttf) format('truetype');
  }
@font-face{
    font-family: "SFPro-Bold";
    src: url(../assets/fonts/SFProDisplay-Bold.ttf) format('truetype');
  }


@font-face{
    font-family: "SFPro-Regular";
    src: url(../assets/fonts/SFProDisplay-Regular.ttf) format('truetype');
  }

  a , a:hover {
      text-decoration: none;
  }
  /* for the logo+text */
.logo {
    width:300px;
    height:45px;
    position: absolute; 
    top: 20px; 
    display: flex;
    padding-left:90px;
    padding-top:0.5%; 
    padding-right:0.5%;
    padding-bottom: 0.5%;
    
}


.altra {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 30px;
    position: absolute;
}

.dimension {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 20px;
    padding-top: 30px;
}

.videoBackground {
    width: 100%;
    position: absolute;
    z-index: -1;
    height: 100vh;
    object-fit: fill;
}

.title {
    color: white;
    font-size: 7vw;
    text-align: center;
    font-family: Courgette;
}

.navbar-default .navbar-toggle.collapsed {
    border-color: black;
    /* background-color: blue; */
}

.navBar {
    /* background-color: rgba(0, 0, 0.5, 0.3); */
    height: 80px;
    padding : 0px 100px 0 100px;
}

.menu {
    font-family: "SFPro-Bold";
    border: 2px solid black ;
    border-radius: 5px;
    padding: 5px 10px 5px 10px;
    font-size: 0.8rem;
    text-align: center;  
    color:black;
}

.menu:hover{
    background-color: black;
    color: #fff;
}

.learnmore {
    text-align: center;
}


.mr-auto{
    float:right;
    /* background-color: blue; */
}



h2 {
    color: white;
}

.btnLearnMore {
    background-color: #3b6eff;
}

.btnContactUs {
    background-color: white;
    color: black;
    /* color of contact */
}

.textLearnMore {
    color: white;
}

.homeBtn {
    padding: 10px;
    border: none;
    border-radius: 0.3vw;
}

.homeBtnText {
    font-size: 1.12vw;
    font-family: "SFPro-r";
}

i {
    border: solid white;
    border-width: 0 3px 3px 0;
    display: inline-block;
    width: 1.5vw;
    height: 1.5vw;
    position: absolute;
    margin-top: 30vw;
    margin-left: 5vw;
    margin-bottom: 1vw;
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    animation: "shake";
    animation-iteration-count: infinite;
}

.titleContainer {
    padding: 200px 30px;
    height: 100vh;
    margin-top: 70px;
}



.divLearnMore {
    align-items: center;
}

.divContactUs {
    align-items: center;
}

.homepagebutton {
    width: 100%;
    font-family: "SFPro-r";
}

.homepagebutton:hover {
    cursor: pointer;
    width: 80%;
    color: black;
    background-color: blueviolet;
}

.homepageButton {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.homepageButton button {
    background-color: #243b55;
    color: #fff;
    border: 2px solid #fff;
    width: 50%;
    margin-top: 1em;
    padding: 8px 0px;
    font-size: 1em;
    font-weight: lighter;
    letter-spacing: 1px;
    margin-bottom: 0.25em;
}

.homepageButton button:hover {
    color: #243b55;
    background-color: #fff;
    border: 2px solid #243b55;
}
.span-n {
    font-family: "OCRA", cursive;
    font-size: 20px;
    color:#13a7ea;
    margin-top: 5px;
    font-weight:normal;
    gap:2px;
    /* background-color: yellow; */
  }

.learn {
    text-align: center;
    font-size: 4vw;
}

.menuBar {
    width: 30px;
    display: block;
    margin-right: 10px;
}
.footerText {
    font-family: SFPro-r;
}

.navActionButtons {
    padding-right: 80px;
}


@media only screen and (max-width:600px){
   
    .mr-auto{
        display:flex;
        justify-content:space-between;

    }
    
    .menuBar{
       display:block; 
    }
    .menu{
        display:flex;
        flex-direction: row;
        padding-right: 10px !important;
    }
}

@media only screen and (max-width:600px){
    .span-n{
     display:none;
    }
    .img{
        margin-left: 5px;
    }
 }

 @media screen and (min-width: 1200px) {
    .divLearnMore {
        padding-left: 50px;
    }
}

@media screen and (max-width: 600px) {
    .logo {
        position: absolute;
        top: 25px;
        left:40px;
        margin-left:-8px;
        text-align: left;
        margin:0;
        padding:0px;
        padding-left: -8px;
        width: 180px;
        height:27px;
    }

    .navActionButtons {
        padding: 0;
       margin-left: 14px !important;

    }

    .navActionButtons a {
        padding-right: 0;
    }

    .navLinkHome {
        display: none !important;
    }

}
@media only screen and (min-width:601px) and (max-width:900px){
    .logo {
        position: absolute;
        top: 25px;
        left:80px;
        margin-left:-8px;
        text-align: left;
        margin:0;
        padding:0px;
        padding-left: -8px;
        width: 180px;
        height:27px;
    }

    .navActionButtons {
       padding-right: 40px;
       margin-left: 14px !important;

    }

    .navActionButtons a {
        padding-right: 0;
    }

    .navLinkHome {
        display: none !important;
    }

    .navBar {
        /* background-color: rgba(0, 0, 0.5, 0.3); */
        height: 80px;
        padding : 0px 50px 0 50px;
    }

}

@media only screen and (min-width:1401px){
    .navActionButtons {
        padding-right: 80px;
        margin-left: 14px !important;
 
     }
}


@media (min-width: 800px){
.navbar-expand-lg {
    justify-content: space-between !important;
}
}